import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { RoleResolveService } from "./core/resolver/role-resolve.service";

const routes: Routes = [
  {
		path: "", redirectTo: 'userDashboard', pathMatch: 'full',
	},
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle: "" },
    children: [
      {
        path: "",
        redirectTo: "userDashboard",
        pathMatch: "full"
      },
      {
        path: "dashboard",
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { pageTitle: "routerDashboard" },
        canActivate:[AuthGuard]
      },
      {
        path: "userDashboard",
        loadChildren: () => import('./features/user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule),
        data: { pageTitle: "routerUserDashboard", rights:'gate2b.dashboard.' },
        canActivate:[AuthGuard]
      },
      {
        path: "sales",
        loadChildren: () => import('./features/inbox/inbox.module').then(m => m.InboxModule),
        data: { pageTitle: "routerInbox",rights:'gate2b.sales.' },
        canActivate: [AuthGuard]
      },
      {
        path: "sales/:t.id",
        loadChildren: () => import('./features/inbox/inbox.module').then(m => m.InboxModule),
        data: { pageTitle: "routerInbox",rights:'gate2b.sales.' },
        canActivate: [AuthGuard]
      },
      {
        path: "connections",
        loadChildren: () => import('./features/interfaces/interfaces.module').then(m => m.InterfacesModule),
        data: { pageTitle: "routerInterfaces" ,rights:'gate2b.connections.' },
        canActivate: [AuthGuard]
      },
      { 
        path: 'purchases',
        loadChildren: () => import('./features/outbox/outbox.module').then(m => m.OutboxModule),
        data: { pageTitle: "routerOutbox", rights:'gate2b.purchases.' },
        canActivate: [AuthGuard]
      }, 
      { 
        path: 'purchases/:t.id',
        loadChildren: () => import('./features/outbox/outbox.module').then(m => m.OutboxModule),
        data: { pageTitle: "routerOutbox", rights:'gate2b.purchases.' },
        canActivate: [AuthGuard]
      },    
      {
	      path: 'settings',
	      loadChildren:() => import('./settings/settings.module' ).then(m => m.SettingsModule) ,
        data: {pageTitle: 'routerSettings', rights:'gate2b.settings.'},
        canActivate: [AuthGuard]
			},
      {
        path: 'ebill', 
        loadChildren: () => import('@app/features/ebill/ebill.module').then(m => m.EbillModule),
        data: { pageTitle: "Ebill",rights: 'ebill.'},
        canActivate: [AuthGuard]

      },
      { 
        path: 'print',
        loadChildren: () => import('./features/print/print.module').then(m => m.PrintModule),
        data: { pageTitle: "routerPrint", rights:'print.transactions.' },
        canActivate: [AuthGuard]
      },
      { path: "**", redirectTo: "userDashboard"}
    ]
  },
 
  

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: [RoleResolveService]
})
export class AppRoutingModule {}
