export const environment = {
  production: true,
  apiUrl: {'io-market.com':'https://dhdgu.api.io-market.com/api/v1/gate2bAPI/gate2B',
  'epost.gate2b.com':'https://dhdgu.api.epost.gate2b.com/api/v1/gate2bAPI/gate2B'},
  //apiUrl:'https://api.gate2b.io-procurement.com/api',
  //  apiUrl: 'http://testgate2b.io-procurement.com:5000/api/v1/gate2bAPI/gate2B',
  GATE2B_URL:{'io-market.com':'https://gate2b.io-market.com/#/',
  'epost.gate2b.com':'https://edi.epost.gate2b.com/#/'},

  firebase: {
  },
  API_NODE_END_POINT :  {'io-market.com':'https://dhdgu.api.io-market.com/api/v1/',
  'epost.gate2b.com':'https://dhdgu.api.epost.gate2b.com/api/v1/'},

  API_END_POINT : {'io-market.com':'https://fjy29.api.io-market.com/api/v1/',
  'epost.gate2b.com':'https://fjy29.api.epost.gate2b.com/api/v1/'},

  LOGIN_URL: {'io-market.com':'https://accounts.io-market.com/#/auth/login/',
  'epost.gate2b.com':'https://accounts.epost.gate2b.com/#/auth/login/'},

  // ACCOUNTS_API: "https://fjy29.api.io-market.com/api/v1/users",

  ACCOUNTS_API: {'io-market.com':'https://fjy29.api.io-market.com/api/v1/users',
  'epost.gate2b.com':'https://fjy29.api.epost.gate2b.com/api/v1/users'},


  encryptionKey:'it6Hig8a56YacWPPuduLbA==',
  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  WebProfile:"https://accounts.io-market.com/assets/webComponent/profile.js",
  WebList:"https://accounts.io-market.com/assets/webComponent/options.js",
  WebProfileLight:"https://accounts.io-market.com/assets/webComponent/profileLight.js",
  WebListLight:"https://accounts.io-market.com/assets/webComponent/optionsLight.js",
  // profile_URL :"https://accounts.io-market.com/#/profile",
  profile_URL:{'io-market.com':'https://accounts.io-market.com/#/profile',
    'epost.gate2b.com':'https://accounts.epost.gate2b.com/#/profile'},
  readmore_URL:"https://www.io-market.com/loesungen/edi-netzwerk/",
  COOKIE_ENABLED_DOMAINS:['api-business.io-market.com','dhdgu.api.io-market.com','fjy29.api.io-market.com','fkbwl.api.io-market.com','fkbwl.api.epost.gate2b.com'],
  cookieDomain:".io-market.com",

  checkCookie:"expireCookie",
  tokenExpireTime:"cookieCreatedTime",
  // LOGOUT_URL :"https://accounts.io-market.com/#/auth/logout/",
  LOGOUT_URL:{'io-market.com':'https://accounts.io-market.com/#/auth/logout/',
    'epost.gate2b.com':'https://accounts.epost.gate2b.com/#/auth/logout/'},

  ticket_URL :"http://api.ticket.g2bngt.io-procurement.com/ws/createTicketFromGate2B.cfc?wsdl&method=createTicketFromGate2B",
  UPGRADE_PLAN: {'io-market.com':'https://admin.io-market.com/#/plan',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/plan'},
  
  
    BUY_ADDON: {'io-market.com':'https://admin.io-market.com/#/buyaddon/2',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/buyaddon/2'},
  // UPGRADE_PLAN :"https://admin.io-market.com/#/plan",
  // BUY_ADDON: "https://admin.io-market.com/#/buyaddon/2",
  // PDF2XML: "https://admin.io-market.com/#/buyaddon/3",
  PDF2XML: {'io-market.com':'https://admin.io-market.com/#/buyaddon/3',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/buyaddon/3'},
  // BILLING_URL: "https://admin.io-market.com/#/billingOverview",
  BILLING_URL: {'io-market.com':'https://admin.io-market.com/#/billingOverview',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/billingOverview'},
  // USER_URL: "https://admin.io-market.com/#/userManagement",
  USER_URL: {'io-market.com':'https://admin.io-market.com/#/userManagement',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/userManagement'},
  // BUSINESS_URL: "https://admin.io-market.com/#/companyDetails",
  BUSINESS_URL: {'io-market.com':'https://admin.io-market.com/#/companyDetails',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/companyDetails'},
  privateKey:{key:"MIIEowIBAAKCAQEAzheqwMQrSFIFkQtsiPFKUDHNSqlae2Hfkfr/7J3cF0hrSfcBHcT/63c3gGt02J0dZE+WWcJ0qt4jNZflxUnzuYpPJw+tv+9Kenw/ghHNKtfiaB20SPl6naOKusPrTbWTjC7MnL3jbs9wBtZxC14vufQ5E1TyHKKxuQtW93ej9OclK/e3aoCv68yhQbHEzS2W8mcp8YfzeyF+BkoMGfPYUgeuV+XLSPrgoK6PBPZlAZe7gSlCwo+bbwg3ShafZcRUMg2ParSP3y1DMaoyxmRERWEChyzbBSYzARrzoV75I4zkzySbnY16VV7Rxtmxv08EGJM89auNx09lpO+fJr30WQIDAQABAoIBAF3ZgTSPIHaqg0ZtwVsLJiAmzam2PTH6e+jqOCj0KgmgPNtN1ABOeYwsmeWtCWSrB3tqkrkFSEOXLMl3Bnc1Gfvf8/OBvEmvGmd0tSbyHE/qR3vKKxMgKKook0BBf6G/khVAKoUmI6+OXyF9/bSDi5BOmr8m66oGnI/7jfrbTJp05qO5VQZDVoc5DxFqDgDNr8pqCZhMaYWXD02Oxs+iiHoiFWGth+wjQVvfmd15Yqvwpb09TMB3YVkQ+cnfI9i07SZZ0/o2b8iEFBQTbhNfHDm8QMRJ5k6lcjbREFIWuh0jI6ti9PFFVYnyyTuRioM15rmmGb9iktmW5V9As2WeZgECgYEA72rLgHNHnIjbI5WzioeztRPpyLM09McjuWrxYOh3UFLJvs2mR8KNJ+7ifhqkZRhLP0T/jnNSiWN67mjKANGClJX1ODnZalXAoz4fGH5tUR7VClCFuRUI3bSYSFSkMwmoBipKWykkrf5TcgO9KO43T0gpRs/VihlbUO2EMdH2NLECgYEA3F36SOFCpFTp0doHolB+KopgP8T7Cc2MbbliuAoejOXdE+mcG4MJ7hdRP9g1y0zPBvD1tHdHfFbP2/wFKupfaYca5N0dWefRL/2inz/bXdpWFCN76Wv7bQGLocSxD9aZEXAgjcHU2ys5vHyTtzMm7JMXezIvevIdrPk/AUN4xCkCgYAJ78TCcAZo2MU/lNb+yOMMgOcTDV0FS76P04BxJTTOgKEe6iockFaLcb60vSZVi8yTs6lnoCGs0hQoEPFRV2C0CgVDcCM15pk4LTvdKGIhG8Z7xxvFsrnLedNYLSsjcyMmHls3rV14WzXbZYxURo7FG5ma9sVGFgdUNIdoCe7OAQKBgHjXSAJjFEpNDhM3fmwxu53UV8dgW1Ea2GDrAglt8ybMfhkHR8WGbBGV08Yw5/7d1cE2rWYS6q5yZHV7k9HeyAd0QPkzj37Xskygx2aQe9jRe9Z7Rt3PiwZRjKJe28GVqkZ3uEJEAldVMsn7fn09naCpYHz8HjFLR56s5zHoL/dRAoGBAIlchmCpCCZeWNxZ+EbGtqJHPCC7tm2symaMydadmNNYvWKE1AznO6if7LrdrmZU0tqJG0ut2bYAinsLei/gUQyOWSUgm6bMQ4lpsBfkCNAxztDviOOwi3Av5aTs6Ic00eMWUHWSwQJIMrPmzQBxzxy9itB6x14UdAw5ivJfkf2D"},
  languageCookie:'languageCookie',
  styleCookie:'styleCookie',
  // API_END_POINT_ADMIN : 'https://fkbwl.api.io-market.com/api/v1/',
  API_END_POINT_ADMIN: {'io-market.com':'https://fkbwl.api.io-market.com/api/v1/',
    'epost.gate2b.com':'https://fkbwl.api.epost.gate2b.com/api/v1/'},
  // XSD_API_HOST : 'https://dhdgu.api.io-market.com/api/v1/gate2bAPI/',
  XSD_API_HOST : {'io-market.com':'https://dhdgu.api.io-market.com/api/v1/gate2bAPI/',
    'epost.gate2b.com':'https://dhdgu.api.epost.gate2b.com/api/v1/gate2bAPI/'},
  FORMATS:{'OpenTrans_Special2.1':'.xml','UBL3.0.1':'.XML','UBL3.1':'.XML','ZUGFERD1.0':'.PDF','UBL2.1':'.XML','ABADOC2.0034':'.pdf','EDIFACTD.96A':'.edi','CXMLWithPDF':'.xml', "CXML1.2":'.xml', 'ETS3.1':'.xml', 'SAP02' :'.xml', 'OpenTrans2.1':'.xml','EDIFACTD.01B':'.edi','YellowBill2.0':'.xml','SignedYellowBill2.0':'.xml','Comarch1.0':'.xml','ETS2.0':'.xml','SAP05':'.xml','QR PDF':'.pdf','Meta Coded PDF':'.pdf' },
  // ACCOUNTS_URL:'https://accounts.io-market.com/#/',
  ACCOUNTS_URL:{'io-market.com':'https://accounts.io-market.com/#/',
    'epost.gate2b.com':'https://accounts.epost.gate2b.com/#/'},
    // ADMIN_URL:"https://admin.io-market.com/#/",
  // ADMIN_URL:"https://admin.io-market.com/#/",
  ADMIN_URL:{
    'io-market.com':'https://admin.io-market.com/#/',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/'},
    
  WC_APPS:"https://wc.io-market.com/subscribedApps/main.js",
  WC_PROFILE:"https://wc.io-market.com/profile/main.js",
  USER_ACTIVE_CHECK:"isUserActive",
  BEXIO_URL:"https://bexio.io-market.com/",
  // SUPPORT_URL: "https://support.io-market.com/#/edi/",
  SUPPORT_URL:{
    'io-market.com':'https://support.io-market.com/#/edi/',
    'epost.gate2b.com':'https://support.epost.gate2b.com/#/edi/'},
  userInfo:"userInfo",
  APPLICATION_VERSION:"8",
  posfinance_id:"41100000007797776",
  interconnect_posfinance_receive_iomID:"41100000000023711",
  MTC_URL: "https://mtc.io-market.com/#/",
  mtc_cookie: "mtc_cookie",
  EBILL_MAIL:"ebillgateway@io-market.com",
  permissions: 'permissions',
  EBILL_QR_INVOICE: "D_EBILL_QR_PDF_SPEZIFIKATION_DE_2022.PDF",
  color:"",
  EDI_MAIL:"edigateway@io-market.com",
  PDFHEADER_MAIL:"PDFHeader@io-market.com",
  PDFHEADERITEM_MAIL:"PDFHeaderItem@io-market.com",
  EBILL_ERROR_PRICE:"0.20",
  cookieConsent: 'cookieConsent',
  tax:"0.081",
  COMPARE_API: "https://dwncu.api.io-market.com/api/v1/main/",
  UPGRADE_PACKAGE :{
    'io-market.com':'https://admin.io-market.com/#/manageSubscription/package',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/manageSubscription/package'},


  STYLES : {
  'io-market.com':[{'--theme-color-1':'#8B9635'},{'--header-color':'#474d226e'},{'--logo-color':'transparent'}],
  'epost.gate2b.com':[{'--theme-color-1':'#ffcc00'},{'--header-color':'transparent'},{'--logo-color':'transparent'}]},

  
  LOGO_ORIGINAL : {
  'io-market.com':'logo.png',
  'epost.gate2b.com':'e-plus-p-logo.png'},

  LOGO : {"dark":{"io-market.com": "logo.png","epost.gate2b.com": "e_plus_logo_final.png"},
      "light":{ "io-market.com": "iomlogowhite.png","epost.gate2b.com": "e_plus_logo_final.png"}
    },

  FAVI : {
  'io-market.com':[{"icon":'favicon_1.ico',"title":"accounts.io-procurement.com"}],
  'epost.gate2b.com':[{"icon":'ePost Logo Icon.svg',"title":"accounts.epost.gate2b.com"}]},

  FOOTER_TEXT : {
  'io-market.com':'iomarket',
  'epost.gate2b.com':'iomarket'},
  HELP_DESK_CONTACT:  {
    "io-market.com": 'helpdesk@io-market.com',
    "epost.gate2b.com": 'pascal.leutenegger@epostservice.ch',
    "xatena.io-market.com": 'helpdesk@io-market.com'
  },
  SALES_TEAM_CONTACT:{
    "io-market.com": 'sales@io-market.com',
    "epost.gate2b.com": 'cristina.goncalves@epostservice.ch',
    "xatena.io-market.com": 'sales@io-market.com'
  },
  COMPANY_NAME: {
    'io-market.com':'iomarket AG',
    'epost.gate2b.com':'ePost Service AG'
  }
};